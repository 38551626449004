/*eslint-disable*/ module.exports = {
  messages: {
    "% OFF": "% de descuento",
    "% OFF booking now": "% de descuento reservando ahora",
    "(sometimes this helps)": "(algunas veces esto ayuda)",
    "*Contact time may vary depending on business hours.":
      "*El tiempo de contacto puede variar dependiendo del horario hábil.",
    "+56 2 2840 0772": "+56 2 2840 0772",
    "- GPS for each of your machines": "- GPS para cada una de tus máquinas",
    "- Hour meter and odometer": "- Horómetro y odómetro",
    "- Reports on your operation, and much more!":
      "- Reportes sobre tu operación, ¡y mucho más!",
    "77777777-7": "77777777-7",
    "98798789-7": "98798789-7",
    "A mail is on your way, please confirm your email to receive weekly offers and updates!":
      "Te hemos enviado un mail, por favor confirma tu correo para recibir ofertas y actualizaciones",
    "About us": "Nosotros",
    "Accept budget": "Aceptar cotización",
    "Accept quote": "Aceptar cotización",
    "Account validation required before accessing your data.":
      "Validación de cuenta requerida antes de acceder a tus datos",
    "Add a product": "Añadir un producto",
    "Add another product": "Añadir otro producto",
    "Add new addreess": "Añadir nueva dirección",
    "Add new address": "Añadir nueva dirección",
    "Add to cart": "Añadir al carrito",
    "Add to quote": "Añadir a cotización",
    Address: "Dirección",
    "Advance payment with only part of the lease, and thus ensure you have your equipment today":
      "Adelanta el pago con solo una parte del arriendo, y así asegura tener tu equipo hoy mismo",
    "Aerial work platforms": "Elevadores tijera",
    All: "Todos",
    "All of your data will be permanently removed from our servers forever. This action cannot be undone.":
      "Tendrás que añadir los productos nuevamente.",
    "All rights reserved": "Todos los derechos reservados",
    "Also, access these benefits": "Además, accede a estos beneficios",
    "And get a discount of": "Y obtén un descuento de",
    "And what they have said": "Y lo que han dicho",
    "And what they've said": "Y lo que han dicho",
    "Are you a provider? Rent or sell with us":
      "¿Eres un proveedor? Arrienda o vende con nosotros",
    "Are you sure you want to empty your cart?":
      "¿Seguro quieres vaciar tu carrito?",
    "Are you sure you want to remove this address? This action cannot be undone.":
      "¿Estás seguro de querer eliminar esta dirección? Esta acción no puede revertirse.",
    "Are you sure you want to remove this item from your cart?":
      "¿Seguro que quieres eliminar este elemento de tu carrito?",
    "Articulated arms, scissor lifts, excavators, trucks, and much more!":
      "Brazos articulados, elevadores tijera, excavadoras, camiones, ¡y mucho más!",
    "At RendaloMaq we give you the peace of mind knowing that in the event that your equipment presents a problem, we will give you our 100% support. We take your satisfaction very seriously and are committed to investigating and responding to any eligible claim that is filed in connection with a unit purchased through us that does not meet the specifications outlined in our inspection report, photos, videos, or service records. maintenance. If, after our investigation, we identify an eligible defect under the terms of our warranty, we will take appropriate steps to remedy it. In these cases, we may reimburse the buyer for eligible maintenance costs or, if necessary, cancel the sale and facilitate the return of the equipment to the seller, with a corresponding refund to the buyer.":
      "En RendaloMaq te damos la tranquilidad de saber que en caso de que tu equipo presente algún problema, nosotros te brindaremos nuestro respaldo al 100%. Tenemos muy en cuenta tu satisfacción y nos comprometemos a investigar y responder ante cualquier reclamo elegible que se presente en relación con una unidad adquirida a través de nosotros y que no cumpla con las especificaciones descritas en nuestro informe de inspección, fotografías, videos o registros de mantenimiento. Si después de nuestra investigación, identificamos un defecto elegible según los términos de nuestra garantía, tomaremos las medidas adecuadas para solucionarlo. En estos casos, podremos reembolsar al comprador los costos de mantenimiento elegibles o si es necesario, cancelar la venta y facilitar la devolución del equipo al vendedor, con un correspondiente reembolso al comprador.",
    "At RendaloMaq we help you rent or sell them so you can get more out of them":
      "En RendaloMaq te ayudamos a arrendarlas o venderlas para que puedas sacarles mayor provecho",
    "At RendaloMaq we help you! Articulated arms, scissor lifts, excavators, trucks, and much more!":
      "¡En RendaloMaq te ayudamos! Brazos articulados, elevadores tijera, excavadoras, camiones, ¡y mucho más!",
    "At RendaloMaq we know that your investment in industrial equipment was high and cost you effort, that is why we take the rental, sale or even transfer of your equipment very seriously and responsibly.":
      "En RendaloMaq sabemos que tu inversión en equipos industriales fue alta y te costó esfuerzo, por eso nos tomamos con mucha seriedad y responsabilidad el arriendo, venta o incluso traslado de tus equipos.",
    "At RendaloMaq we solve the rental and purchase of machinery in the simplest, fastest and most efficient way":
      "En RendaloMaq solucionamos el arriendo y compra de maquinaria de la manera más simple, rápida y eficiente",
    "At the time of quoting with us, you will receive an email with all the necessary information so that you can make an informed decision.":
      "Una vez que hayas cotizado con nosotros, recibirás un correo electrónico con toda la información necesaria para que puedas tomar una decisión informada.",
    Attributes: "Atributos",
    "Authorization code": "Código de autorización",
    "Authorization date": "Fecha de autorización",
    "Avg. price from": "Arriendo desde",
    "Back to catalog": "Volver al catálogo",
    "Be part of our suppliers and enjoy the benefits!":
      "¡Sé parte de nuestros proveedores y disfruta de los beneficios!",
    "Become a supplier now!": "¡Conviértete en proveedor ahora!",
    Benefits: "Beneficios",
    "Between 1 and 3 months": "Entre 1 a 3 meses",
    "Billing information": "Información de facturación",
    Blog: "Blog",
    "Book now": "Reservar ahora",
    "Book now with discount": "Reservar ahora con descuento",
    "Book today and pay only": "Reserva hoy y paga tan solo",
    "Book your equipment today!": "¡Reserva tu equipo ahora!",
    Booking: "Reserva",
    "Booking discount": "Descuento por reserva",
    "Booking value": "Valor de reserva",
    "Budget online": "Cotización online",
    Budgets: "Cotizaciones",
    "Business name": "Razón social",
    Buy: "Comprar",
    "Buy new and used machinery": "Compra maquinaria nueva y usada",
    "By continuing we will generate a quote document with the data you provide us":
      "Al continuar generaremos un documento de cotización con los datos que nos proveas",
    Cancel: "Cancelar",
    "Cant find the machine you are looking for?":
      "¿No encuentras la máquina que estás buscando?",
    Card: "Tarjeta",
    Cart: "Carrito",
    Catalog: "Catálogo",
    "Catalog title":
      "Descubre las maquinas que tenemos disponibles. Cobertura nacional garantizada.",
    Categories: "Categorías",
    Characteristics: "Características",
    "Check prices": "Ver precios",
    City: "Ciudad",
    Client: "Cliente",
    "Client name": "Nombre del cliente",
    "Clientes, RendaloMaq te llamará cuando haya arrendatarios o compradores interesados sin necesidad de que tú los tengas que buscar.":
      "Clientes, RendaloMaq te llamará cuando haya arrendatarios o compradores interesados sin necesidad de que tú los tengas que buscar.",
    Clients: "Clientes",
    Code: "Código",
    "Commonly rented": "Comúnmente arrendados",
    "Commonly rented...": "Comúnmente arrendadas...",
    "Commonly searched for..": "Maquinaria comúnmente buscada...",
    "Commonly searched for...": "Maquinaria comúnmente buscada...",
    Company: "Empresa",
    "Company DNI": "RUT de la empresa",
    "Company info": "Información de la empresa",
    "Company name": "Nombre de tu empresa",
    "Confirm the conditions and we finalize the lease and/or sale":
      "Confírmanos las condiciones pactadas en la propuesta para así concretar el arriendo y/o la compra de lo que solicitaste",
    Contact: "Contáctanos",
    "Contact my agent": "Contactar a mi agente comercial",
    "Contact support": "Contacta a soporte",
    "Contact us": "Contáctanos",
    "Contact us and tell us if you want to lease and/or sell machinery.":
      "Contáctanos y cuéntanos si quieres arrendar y/o vender maquinaria.",
    "Contact us and we find it for you!":
      "¡Contáctanos y la encontramos por ti!",
    Continue: "Continuar",
    "Continue exploring": "Continuar explorando",
    "Control, you decide the price, quantity and type of machines to rent or sell.":
      "Control, tú decides el precio, la cantidad y el tipo de máquinas para arrendar o vender.",
    "Create account": "Crear cuenta",
    "Create new password": "Crear nueva contraseña",
    Customer: "Cliente",
    "Customers, we receive a percentage of profit on each lease or sale, although you do not pay it directly.":
      "Clientes, percibimos un porcentaje de ganancia en cada arriendo o venta, aunque no la pagas directamente tú.",
    DNI: "RUT",
    "Data sheet": "Ficha técnica",
    "Data, our software for industrial machines shows you valuable information about the status of your equipment in real time.":
      "Datos, nuestro software para máquinas te enseña información  valiosa sobre el estado de tus equipos en tiempo real.",
    Date: "Fecha",
    Day: "Día",
    "Day from": "Día desde",
    "Days quantity": "Cantidad de días",
    "Days:": "Días:",
    "Decide later": "Decidir después",
    "Delivery address": "Dirección de entrega",
    Description: "Descripción",
    Destination: "Destino",
    Detail: "Detalle",
    Dia: "Dia",
    "Did you forget your password?": "¿Olvidaste tu contraseña?",
    "Digital platform that will allow you to monitor the status of your machines in real time":
      "Plataforma digital que te permitirá monitorear en tiempo real el estado de tus máquinas",
    Discount: "Descuento",
    "Disponibilidad 100% garantizada en todo Chile.":
      "Disponibilidad 100% garantizada en todo Chile.",
    "Do not complicate yourself! With RendaloMaq you will not have to make transfer and security efforts if you need it and we will even help you manage with your potential clients.":
      "¡No te compliques! Con RendaloMaq no tendrás que hacer esfuerzos de traslado y seguridad si es que así lo necesitas e incluso, te ayudamos a gestionar con tus potenciales clientes. ",
    "Do not worry! Enter your email and we will help you recover your password":
      "¡No te preocupes! Ingresa tu correo y te ayudaremos a recuperar tu contraseña",
    "Do not worry, we have also sent it to your email":
      "También lo hemos enviado a tu email",
    "Do they really not charge me additional commission?":
      "¿De verdad no me cobran comisión adicional?",
    "Do you an account already? Quote faster by log in":
      "¿Ya tienes una cuenta? Cotiza más rápido iniciando sesión",
    "Do you have a coupon?": "¿Tienes un cupón de descuento?",
    "Do you have a machine that you want to lease or sell?":
      "¿Tienes una máquina que quieras arrendar o vender?",
    "Do you have machinery and want to make it profitable?":
      "¿Tienes maquinaria y quieres rentabilizarla?",
    "Do you need something specific? Let's talk!":
      "¿Necesitas algo en específico? ¡Conversemos!",
    "Do you need something specific? Lets talk!":
      "¿Necesitas algo en específico? ¡Conversemos! ",
    "Do you need to lease or buy machinery?":
      "¿Necesitas arrendar o comprar maquinaria?",
    "Do you need to lease or buy machinery?\nAt RendaloMaq we help you!":
      "¿Necesitas arrendar o comprar maquinaria? ¡En RendaloMaq te ayudamos!",
    "Do you want to know more?": "¿Quieres saber más?",
    "Do your industrial machines spend more time parked than leased?":
      "¿Tus máquinas pasan más tiempo estacionadas que arrendadas?",
    Docs: "Documentación",
    "Document detail": "Detalle de documento",
    "Does leasing my machines through RendaloMaq put me under any special obligation?":
      "¿Arrendar mis máquinas a través de RendaloMaq me pone en alguna obligación especial?",
    Download: "Descargar",
    "Download ebook": "Descargar ebook",
    "Download it anyway": "Descargar de todas formas",
    "Download our app for providers": "Descarga nuestra app para proveedores",
    "Download receipt": "Descargar comprobante",
    "Download your budget": "Descarga tu cotización",
    "During the next year": "Dentro de un año",
    "Earth moving": "Movimiento de tierra",
    Edit: "Editar",
    Email: "Email",
    "Email us": "Envianos un correo",
    "Empty cart": "Vaciar carrito",
    "Enroll now": "Inscríbete ahora",
    "Enter a new password for your account":
      "Ingresa una nueva contraseña para tu cuenta",
    "Enter your email address and we will send you a link to reset your":
      "Ingresa tu correo y te enviaremos un link para resetear tu",
    "Enter your email address and we will send you a link to reset your password.":
      "Ingrese su dirección de correo electrónico y le enviaremos un enlace para restablecer su contraseña.",
    "Enter your info and": "Ingresa tu información y",
    Equipment: "Equipo",
    "Equipment available!": "¡Equipo disponible!",
    "Equipment available, book it now!":
      "¡Equipo disponible, resérvalo ahora mismo!",
    "Estimated duration": "Duración estimada",
    "Estimated number of days": "Cantidad estimada de días",
    "Ex: Moneda 1202, Santiago": "Ejemplo: Moneda 1202, Santiago",
    "Expansion, we want to bring solutions to the industrial sector throughout Latin America, we are already in Chile, Mexico and Brazil, with your vote of confidence we will continue to grow.":
      "Expansión, queremos llevar soluciones al rubro industrial en toda latinoamérica, ya estamos en Chile, México y Brasil, con tu voto de confianza seguiremos creciendo.",
    "Fee amount": "Monto por cuota",
    "Fee qty": "Cantidad de cuotas",
    Filters: "Filtros",
    "Final value to quote": "Valor final a cotizar",
    "Finish the required info and validate your data":
      "Completa la información necesaria y valida los datos",
    "For clients": "Para clientes",
    "For providers": "Para proveedores",
    "Forget about delinquencies or other intermediaries that charge you an additional commission, because at RendaloMaq we will be your best client, since we assume the commercial risk.":
      "Olvídate de la morosidad o de otros intermediarios que te cobran comisión adicional, porque en RendaloMaq seremos tu mejor cliente, pues asumimos el riesgo comercial.",
    Free: "Costo 0",
    "Frequently asked questions": "Preguntas frecuentes",
    From: "From",
    "From getting clients to handle guarantees":
      "RendaloMaq se encarga de todo por ti, desde conseguir clientes hasta manejar garantías",
    "From:": "Desde:",
    "Full name": "Nombre completo",
    "Get you interested customers.": "Conseguirte clientes interesados.",
    "Go back": "Volver",
    "Go back home": "Vuelve al inicio",
    "Go to quote": "Ir a la cotización",
    Guarantee: "Garantía",
    "Guarantee the availability of this equipment today, paying only":
      "Garantiza la disponibilidad de este equipo hoy, pagando solo",
    "Guarantee your equipment today!": "¡Asegura tu equipo ahora!",
    "Guerantee your rent and do not worry!":
      "Garantiza tu arriendo y ¡despreocúpate!",
    "Hello!": "¡Hola!",
    Help: "Ayuda",
    "Hold on!": "Espera",
    Home: "Home",
    Horometer: "Horómetro",
    Hours: "Horas",
    "How do we do it?": "¿Cómo lo hacemos?",
    "How does RendaloMaq works?": "¿Cómo funciona RendaloMaq?",
    "How does it work?": "¿Cómo funciona?",
    "I already have an account!": "¡Ya tengo una cuenta!",
    "I am interested in...": "Estoy interesado en...",
    "I am looking for a machine with these kind of specifications... to realize these kind of works...":
      "Estoy buscando una máquina con este tipo de especificaciones... para realizar este tipo de trabajos...",
    "I am searching": "Estoy buscando",
    "I want this discount": "Quiero este descuento",
    "I want this machine": "Quiero esta máquina",
    "I want to be a part!": "¡Quiero ser parte!",
    "I want to be a provider!": "¡Quiero ser proveedor!",
    "I want to be a supplier!": "¡Quiero ser proveedor!",
    "I want to know more!": "¡Quiero saber más!",
    "I want to lease a machine like this one":
      "Quiero arrendar una máquina como esta",
    "I want to register!": "¡Quiero inscribirme!",
    "I want to see the benefits!": "¡Quiero ver todos los beneficios!",
    "I want to stay": "Quiero quedarme",
    IVA: "IVA",
    "If you agree, we will assign you as a provider in our network, where we will lease and/or sell your machines when our customers request it and you authorize it.":
      "De estar de acuerdo, te asignaremos como proveedor en nuestra red, donde pondremos en arriendo y/o venta tus máquinas cuando nuestros clientes lo soliciten y tú lo autorices.",
    "If you change the current country, all the items in your cart will be removed. Do you want to continue?":
      "Si cambia el país actual, se eliminarán todos los artículos de su carrito. ¿Quieres continuar?",
    "If you do not pass the validation, do not worry: we will refund your reservation in full.":
      "Si no pasas la validación, no te preocupes: te reembolsaremos el dinero de la reserva en su totalidad.",
    "If you lease your machines through us, we take care of:":
      "Si arriendas tus máquinas a través de nosotros, nos encargamos de:",
    "If you need to deal with something urgently, you can always reach to us trough Whatsapp":
      "Si necesitas resolver algo de forma urgente, siempre puedes contactarnos mediante Whatsapp",
    "Im interested": "Me interesa",
    "Imagenes referenciales": "Imágenes referenciales",
    Immediate: "Inmediata",
    "Immediate purchase availability": "Disponibilidad de compra inmediata",
    "In a nutshell, we make it easy for you to monitor and calculate profit per machine, on an easy-to-use platform, backed by data that you can access anytime, anywhere.":
      "En pocas palabras, te facilitamos el monitoreo y cálculo de ganancia por máquina, en una plataforma fácil de usar, con respaldo de la información a la que puedes acceder en cualquier momento y lugar.",
    "In addition, we provide you with our fleet management and geolocation platform, so that you can be up to date on the status of your assets at all times.":
      "Además, te entregamos nuestra plataforma de administración de flotas y geolocalización, para que puedas estar al tanto en todo momento sobre el estado de tus activos.",
    "In order to be clear about the machines available for rent and so that you always know where your equipment is, we install a GPS on the machines you decide to rent, at no additional cost.":
      "Para tener claridad de las máquinas disponibles en arriendo y que tú sepas siempre en dónde están tus equipos, instalamos un GPS en las máquinas que decidas arrendar, sin costo adicional.",
    "In the meantime this is what you can do:":
      "Mientras tanto, esto es lo que puedes hacer:",
    "In the next six months": "En los próximos seis meses",
    "In the next three months": "En los próximos tres meses",
    "In the same way, consider that you will always have control of the information, at RendaloMaq we will only have access to the availability of your machines, the rest of the information is protected with high security standards and only for your consultation.":
      "De igual manera, considera que tú siempre tendrás control de la información, en RendaloMaq solo tendremos acceso a la disponibilidad de tus máquinas, el resto de la información está resguardada con altos estándares de seguridad y solo para tu consulta.",
    "Inspection summary": "Resumen de inspección",
    "Insurance, to protect your machines during the transfer and stay on the construction site.":
      "Seguro,  para resguardar tus máquinas durante el traslado y estancia en la obra.",
    "Invalid credentials. Please validate all inputs and retry.":
      "Credenciales inválidas. Por favor valida todos los campos e intenta nuevamente.",
    "Invalid name, please introduce your first and last name. Ex: Paz Rodríguez":
      "Nombre inválido, por favor introduce tu nombre y tu apellido. Ej: Paz Rodríguez",
    "Invalid phone number, validate that you have introduced your phone number correctly. Ex: 99999999":
      "Número de teléfono inválido, verifica que has escrito tu número correctamente. Ej: 99999999",
    "It is a digital platform developed by RendaloMaq that shows you real-time information about your machines, such as the occupancy rate, profit per machine, location and rental time.":
      "Es una plataforma digital desarrollada por RendaloMaq que te enseña información en tiempo real sobre tus máquinas, como la tasa de ocupación, ganancia por máquina, ubicación y tiempo de arriendo.",
    "It seems like you are a supplier without customer account. Please contact support to get help.":
      "Al parecer eres un proveedor sin cuenta de cliente. Por favor, contacta al equipo de soporte para obtener ayuda.",
    Item: "Item",
    "Keep your equipment at maximum production":
      "Mantén tus equipos a máxima producción",
    Kilometers: "Kilómetros",
    "Know the prices of excavators": "Conoce los precios de excavadoras",
    "Know the rental prices for scissor lifts":
      "Conoce los precios de arriendo para elevadores alza hombres",
    "Last name": "Apellido",
    Lease: "Arrienda",
    "Lease or sell your machines in RendaloMaq":
      "Arrienda o vende tus máquinas en RendaloMaq",
    Lease_Popup: "Arrendar",
    "Leased machines": "Máquinas arrendadas",
    Leasing: "Arriendo",
    "Let us contact you!": "¡Te contactamos!",
    "Lets chat on WhastApp!": "¡Hablemos por WhatsApp!",
    "Lets chat on WhatsApp!": "¡Hablemos por WhatsApp!",
    "Listen to our podcast!": "¡Escucha nuestro podcast!",
    "Load more": "Cargar más",
    Location: "Región",
    "Log in": "Inicia sesión",
    "Log in as client": "Inicia sesión como cliente",
    "Log in as provider": "Ingresar como proveedor",
    "Log in as supplier": "Ingresar como proveedor",
    "Log out": "Cerrar sesión",
    Login: "Inicia sesión",
    "Login with Google": "Inicia sesión con Google",
    "Look at the prices of electric or diesel articulated boom lifts":
      "Mira los precios de brazos articulados eléctricos o con diésel",
    "Look for machinery, sizes, types of works and more":
      "Busca por maquinaria, tamaños, tipos de trabajo y más",
    Machines: "Máquinas",
    "Machines available from north to south":
      "Máquinas disponibles de norte a sur",
    Mail: "Correo",
    "Maintenance of the machinery for the duration of the lease so that you do not lose productivity":
      "Mantenimiento de la maquinaria mientras dure el arriendo para que no pierdas productividad",
    "Make the corresponding payment": "Realiza el pago correspondiente",
    Month: "Mes",
    "Month from": "Mes desde",
    "More information about this machine":
      "Más información acerca de esta máquina",
    "More than 3 months": "Más de 3 meses",
    "Most wanted": "Más buscados",
    "Most wanted equipment": "Maquinaria más buscada...",
    "My account": "Mi cuenta",
    "My addresses": "Mis direcciones",
    "My profile": "Mi perfil",
    "My requests": "Mis solicitudes",
    Name: "Nombre",
    "Net amount": "Monto neto",
    New: "Nuevo",
    "New password": "Nueva contraseña",
    Next: "Siguiente",
    "No commissions": "Sin comisiones",
    "No results found": "No se han encontrado resultados",
    "No small print": "Sin letra chica",
    "No, it will just make it easier for you to lease your machines more often. Your assets remain 100% yours and you can do whatever you want with them.":
      "No, tan solo te será más fácil arrendar tus máquinas con mayor frecuencia. Tus activos, siguen siendo 100% tuyos y puedes hacer lo que quieras con ellas.",
    Odometer: "Odómetro",
    "Of course we can always advise you with the market rates so that your leases have greater profitability and thus manage to keep your machines busy all the time.":
      "Claro que siempre podremos asesorarte con las tarifas del mercado para que tus arriendos tengan mayor rentabilidady así logres mantener tus máquinas ocupadas todo el tiempo.",
    "Offer!": "¡Oferta!",
    Ok: "Ok",
    "One of our agents will contact you soon":
      "Uno de nuestros agentes te contactará pronto",
    "One of our agents will contact you soon to coordinate the details":
      "Uno de nuestros agentes te contactará pronto para coordinar los detalles",
    Operation: "Operación",
    "Order number": "Número de orden",
    "Organization DNI": "RUT de la empresa",
    Origin: "Origen",
    "Other equipment you could be interested into":
      "Otras máquinas que podrían interesarte",
    "Our advisors will call you in less than 30 minutes":
      "Uno de nuestros expertos te llamará",
    "Our advisors will call you in less than 30 minutes to clarify your doubts":
      "Uno de nuestros expertos te llamará para brindarte una atención personalizada",
    "Our clients": "Algunos de nuestros clientes",
    "Page not found": "Página no encontrada",
    "Paperwork and dealing with the client.":
      "El papeleo y trato con el cliente.",
    Password: "Contraseña",
    "Password changed": "Contraseña modificada",
    "Password changed successfully. You can now log in with your new password.":
      "Contraseña modificada con éxito. Ahora puede iniciar sesión con su nueva contraseña.",
    "Pay now only a part of your lease and guarantee the booking of your equipment. By booking now we will apply a discount of":
      "Paga ahora solamente una fracción del arriendo y asegura la reserva de tu equipo. Si reservas ahora te aplicaremos un",
    "Pay now only the reservation and secure your rental with an additional discount":
      "Paga ahora solo una reserva y asegura tu arriendo con un descuento adicional",
    "Pay only a fraction of the lease":
      "Reserva ahora mismo pagando solo una fracción del arriendo",
    "Pay with your bank": "Paga con tu banco",
    "Payment method": "Método de pago",
    "Payment successfully processed!": "¡Pago procesado exitosamente!",
    "Paz y Asociados SpA": "Paz y Asociados SpA",
    "Personal info": "Información prsonal",
    Phone: "Teléfono",
    "Phone code": "Código de área",
    "Photo gallery": "Galería de fotos",
    "Please check the URL in the address bar and try again.":
      "Verifica la URL en la barra de direcciones e intenta de nuevo",
    "Please check your internet connection and try again. If the issue persists, contact our support team for assistance.":
      "Por favor valida tu conexión a internet e intenta nuevamente. Si el problema persiste, contacta a nuestro equipo de soporte para obtener asistencia",
    "Please validate the link you have received in your email and try again.":
      "Por favor verifica el link que recibiste en tu correo e intenta nuevamente",
    "Please validate this field": "Por favor verifica este campo",
    "Press releases": "Prensa",
    Previous: "Anterior",
    "Previous use": "Uso previo",
    Price: "Precio",
    "Price does not include delivery": "El precio no incluye transporte",
    "Price does not include transport": "El precio no incluye transporte",
    "Privacy Policy": "Política de privacidad",
    "Products to be quoted": "Productos a cotizar",
    Providers: "Proveedores",
    Purchase: "Compra",
    "Purchase without category": "Compra genérica",
    Purchase_Popup: "Comprar",
    Quantity: "Cantidad",
    Quote: "Cotización",
    "Quote and see prices of electric and diesel telescopic boom lifts":
      "Cotiza y mira precios de brazos telescópicos a eléctricos y diésel",
    "Quote from the web the machine you need":
      "Cotiza desde la web la máquina que necesites",
    "Quote online all kinds of heavy machinery.":
      "Cotiza en línea todo tipo de maquinaria pesada",
    "Quote online and start your project today":
      "Cotiza en línea y comienza tu proyecto hoy",
    "Quote pumps, compressors and more": "Cotiza bombas, compresores y más",
    "Quote purchase": "Cotizar compra",
    "Quote rent": "Cotizar arriendo",
    "Ready to get more clients?": "¿Listo para conseguir más clientes?",
    "Really! You have control of the rental or sale prices of your equipment and at RendaloMaq we pay them.":
      "¡De verdad! Tú tienes el control de los precios de arriendo o venta de tus equipos y en RendaloMaq los pagamos.",
    "Recommended for companies": "Recommended for companies",
    "Recover it": "Recupérala",
    "Recover password": "Recuperar contraseña",
    "Redirect me": "Redirígeme",
    "Refresh the page": "Refresca la página",
    Region: "Region",
    "Related machines": "Máquinas relacionadas",
    "Related products": "Productos relacionados",
    Reload: "Recargar",
    "Remember my info for future quotes":
      "Recordar mi contraseña para cotizaciones futuras",
    Remove: "Eliminar",
    "Remove address": "Eliminar dirección",
    "RendaloMaq makes it simple for you!": "¡RendaloMaq te la hace simple! ",
    "RendaloMaq will have the machine in the place you indicate, on the day and at the agreed time":
      "RendaloMaq tendrá las máquinas que solicitaste en el lugar, día y hora que necesites",
    Rent: "Arrendar",
    "Rent articulated boom lift": "Arrienda brazos articulados",
    "Rent construction trucks": "Arrienda camiones de obras",
    "Rent excavators": "Arrienda excavadoras",
    "Rent more, doing less": "Arrienda más, haciendo menos",
    "Rent or sell with RendaloMaq and access great benefits":
      "Arrienda o vende con RendaloMaq y accede a grandes beneficios",
    "Rent scissor lift": "Arrienda elevador de tijera",
    "Rent support equipment": "Arrienda equipos de apoyo",
    "Rent telescopic boom lift": "Arrienda brazos telescópicos",
    "Rent, buy or sell equipment":
      "Arrienda, compra o vende maquinaria con RendaloMaq",
    Rent2: "Arriendo",
    "Rental and Sale of Machinery Online":
      "Arriendo y Venta de Maquinaria Online",
    "Rental from": "Arriendo desde",
    Request: "Solicitud",
    "Request contact": "Solicitar contacto",
    "Request help": "Solicitar ayuda",
    "Request quote": "Solicitar cotización",
    "Respalded by our partners": "Respaldados por nuestros partners",
    "Retry payment": "Reintentar pago",
    "Right now": "Ahora",
    "Risk validation process": "Proceso de validación de riesgos",
    "Round trip transportation": "Transporte ida y vuelta",
    "SEO Catalog description":
      "Realiza tu cotización en línea y suma valor a tu proyecto.",
    "SEO Catalog h1":
      "Descubre las maquinas que tenemos disponibles. Cobertura nacional garantizada.",
    "SEO Catalog h2": "Maquinaria pesada",
    "SEO Catalog h3": "Cobertura nacional",
    "SEO Catalog keywords":
      "Alza hombre, arriendo por dias plataforma, maquinaria pesada",
    "SEO Index description":
      "Somos una plataforma para el arriendo, compra y venta de elevadores de tijera, retroexcavadoras, camión tolva, camión ajibe y otras maquinarias.",
    "SEO Index h2": "Arriendo maquinaria",
    "SEO Index h3": "Venta maquinaria",
    "SEO Index keywords":
      "arriendo plataforma, venta plataforma, arriendo maquinas construccion, venta amarillos",
    "SEO cart description":
      "Al enviar tu solicitud, unos de nuestros ejecutivos te contactará.",
    "SEO cart h1": "Completa tu carrito y solicita tu cotización.",
    "SEO cart h2": "RendaloMaq",
    "SEO cart h3": "Cotiza en línea",
    "SEO cart keywords":
      "Arriendo maquinaria, Solicitar cotizacion, Rendalomaq",
    "SEO clients landing description":
      "Arrienda por días o meses maquinaria pesada para diferentes proyectos.",
    "SEO clients landing h1":
      "Arrienda maquinaria en todo el páis. Cotiza en línea.",
    "SEO clients landing h2": "Arrienda en línea",
    "SEO clients landing h3": "Arrienda maquinaria",
    "SEO clients landing keywords":
      "Arriendo genie, Arriendo camión, Maquinaria arriendo chile",
    "SEO confirmation description":
      "Somos una plataforma para el arriendo, compra y venta de elevadores de tijera, retroexcavadoras, camión tolva, camión ajibe y otras maquinarias.",
    "SEO confirmation h1": "Arrienda, compra o vende maquinaria con RendaloMaq",
    "SEO confirmation h2": "RendaloMaq",
    "SEO confirmation h3": "Arrienda maquinaria",
    "SEO confirmation keywords":
      "Rendalomaq, Arrrienda maquinaria, Venta maquinaria, Proveedores",
    "SEO privacy-policy description":
      "Somos una plataforma para el arriendo, compra y venta de elevadores de tijera, retroexcavadoras, camión tolva, camión ajibe y otras maquinarias.",
    "SEO privacy-policy h2": "RendaloMaq",
    "SEO privacy-policy h3": "Cotiza en línea",
    "SEO privacy-policy keywords":
      "Rendalomaq, Arrrienda maquinaria, Venta maquinaria, Proveedores",
    "SEO suppliers landing description":
      "Sé parte del equipo de RendaloMaq con tus máquinas. ",
    "SEO suppliers landing h1": "Sé proveedor y rentabiliza tus máquinas",
    "SEO suppliers landing h2": "Sé proveedor",
    "SEO suppliers landing h3": "Inscríbete en línea",
    "SEO suppliers landing keywords":
      "Proveedor maquinaria, Proveedor alza hombre, Ofrecer maquinaria",
    "SEO terms-and-conditions description":
      "Cobertura nacional garantizada. Todos los equipos están disponible.",
    "SEO terms-and-conditions h2": "Venta de genie",
    "SEO terms-and-conditions h3": "Llegamos a todo Chile",
    "SEO terms-and-conditions keywords":
      "Arriendo elevador de tijera, Arriendo minicargador, Arriendo retroexcavadora",
    "Sale of new and used machinery": "Venta de maquinaria nueva y usada",
    "Same info as client":
      "Usar la misma información del cliente para la facturación",
    "Satisfied clients": "Clientes satisfechos",
    Save: "Guardar",
    Search: "Buscar",
    "Search for machinery...": "Buscar maquinaria...",
    "Search machine": "Buscar máquina",
    "Search orders": "Buscar órdenes...",
    "Search request...": "Buscar solicitud...",
    "See all": "Ver todo",
    "See all catalog": "Ver todo el catálogo",
    "See all my requests": "Ver todas mis solicitudes",
    "See budget": "Ver presupuesto",
    "See cart": "Ver carrito",
    "See detail": "Ver detalle",
    "See less": "Ver menos",
    "See more": "Ver más",
    "See prices for dump trucks, low beds, boom trucks and more":
      "Mira precios de camiones tolva, cama baja, pluma y más",
    "See quote": "Ver cotización",
    Select: "Seleccionar",
    "Select a category": "Selecciona una categoría",
    "Select a date": "Selecciona una fecha",
    "Select a start date": "Selecciona una fecha de inicio",
    "Select a time": "Seleccionar horario",
    "Select...": "Seleccionar...",
    Share: "Compartir",
    "Show less": "Mostrar menos",
    "Show more": "Mostrar más",
    "Sign in to manage your buy/sell orders, addresses and more.":
      "Inicia sesión para manejar tus órdenes de compra/venta, direcciones y más",
    "Sign me up": "Registrarme",
    "Sign up": "Registrar",
    "Sign up as a provider": "Regístrate como proveedor",
    "Sign up as a provider now!": "¡Registrate como proveedor ahora!",
    "Sign up here": "Registrate acá",
    "Sign up with Google": "Registrarte con Google",
    Solutions: "Soluciones",
    "Some attributes that you can find with RendaloMaq:":
      "Algunos atributos que podrás encontrar con RendaloMaq:",
    "Some reservations go through a risk validation process, especially if you are not a frequent customer. This means that we may ask you for documents such as the <0>Tax File</0> and the <1>RUT of the Legal Representative</1> to ensure safe use of our machines.":
      "Algunas reservas pasan por un proceso de validación de riesgos, especialmente si no eres cliente frecuente. Esto significa que es posible que te solicitamos documentos como la <0>Carpeta de impuestos</0> y el <1>RUT del Representante Legal</1> para garantizar el uso seguro de nuestros equipos.",
    "Something is wrong...": "Algo está mal...",
    "Sort by": "Ordenar por",
    "Specific purchase": "Compra específica",
    Specifications: "Especificaciones",
    Sr: "Señor(a/es/as)",
    "Start today to enjoy all the benefits we have for our customers":
      "Empieza hoy mismo a disfrutar de todos los beneficios que tenemos para nuestros clientes",
    State: "Región",
    Status: "Estado",
    Step: "Paso",
    "Still unsure? Lets talk!": "¿Tienes dudas sobre la cotización? ¡Hablemos!",
    Subscribe: "Suscribirse",
    "Subscribe to our newsletter": "Suscríbete a nuestro newsletter",
    Subtotal: "Subtotal",
    "Successfully added to cart": "Se añadió al carrito",
    "Successfully added to quote": "Añadido exitosamente a tu cotización",
    "Suppliers dashboard": "Dashboard para proveedores",
    Support: "Soporte",
    "Take a look at all the information you need to make the best decision.":
      "Echa un vistazo a toda la información que necesitas para tomar la mejor decisión.",
    "Take advantage of this offer before time runs out!":
      "¡Aprovecha esta oferta antes de que el tiempo se acabe!",
    "Take me to login": "Llévame al inicio de sesión",
    "Technical data": "Inspección técnica",
    Telemetry: "Telemetría",
    "Tell us what you are looking for": "Cuéntanos lo que estás buscando",
    "Terms of service": "Términos de servicio",
    "Thank you for choosing RendaloMaq": "Gracias por elegir RendaloMaq",
    "Thank you for choosing RendaloMaq!": "¡Gracias por preferir RendaloMaq!",
    "Thank you for trusting us. If you have any questions, we are here to help you 😄":
      "¡Gracias por confiar en nosotros. Si tienes alguna pregunta, estamos aquí para ayudarte 😄",
    Thanks: "Gracias",
    "Thanks for accepting!": "¡Gracias por aceptar!",
    "Thanks for answering!": "¡Gracias por responder!",
    "Thanks for trusting us. If we need additional information to complete your process, we will contact you. Stay tuned to your contact media.":
      "Gracias por confiar en nosotros. Si necesitamos alguna información extra para completar el proceso te contactaremos. Mantente atento a los canales de comunicación que nos proveíste.",
    "Thanks for validating your email! You will be redirected to the login in a few seconds":
      "¡Gracias por validar tu correo! Serás redirigido a iniciar sesión en unos segundos",
    "Thanks!": "¡Gracias!",
    "The DNI is incorrect, please validate it":
      "EL RUT ingresado es incorrecto, por favor validalo.",
    "The DNI you have entered is invalid. Please validate.":
      "El RUT que has ingresado es inválido. Por favor verificalo.",
    "The DNI youre trying to register already exist.":
      "El RUT que estás tratando de registrar ya ha sido registrado.",
    "The email youre trying to register already exist.":
      "El correo que estás tratando de registrar ya existe.",
    "The equipment, round trip transportation and IVA":
      "Equipo + Transporte + IVA",
    "The help is on its way!": "La ayuda está en camino",
    "The number of days you estimate the lease will last":
      "El número de días que estimas que durará el arriendo",
    "The pin in the map is draggable so you can make the address more specific":
      "Puedes arrastrar el pin para hacer la dirección más especifica",
    "The round trip transportation of your machines, in all regions of the country.":
      "El traslado ida y vuelta de tus máquinas, en todas las regiones del país.",
    "There has been an error in your submission":
      "Ha habido un error en tu solicitud",
    "There has been an error in your validation":
      "Ha habido un error en tu validación",
    "There is no schedule for this day, please select another":
      "No hay horarios disponibles para este día, por favor seleccione otro",
    "This coupon does not exist":
      "El cupón que estás tratando de utilizar no existe o ya expiró",
    "This email is already registered. Try to log in or recover your password.":
      "Este email ya ha sido registrado. Intenta iniciar sesión o recuperar tu contraseña.",
    "This field is required": "Este campo es obligatorio",
    "This phone number is too long": "El número es demasiado largo",
    "This phone number is too short": "El número es demasiado corto",
    "This process helps us protect everyones safety. If you have any questions, we are here to help.":
      "Este proceso nos ayuda a proteger la seguridad de todos. Si tienes alguna pregunta, estamos aquí para ayudarte.",
    "This rental request is no longer available, we hope the next rental request will be more aligned with you, stay tuned!":
      "Esta solicitud de arriendo no está disponible, esperamos que la próxima solicitud esté más alineada contigo, ¡mantente atento!",
    To: "Para",
    "To complete this step, we may ask you for some documents, such as the <0>Tax File</0> and the <1>RUT of the Legal Representative of the company</1>. In some cases, it may be necessary to request additional information.":
      "Para completar este paso, es posible que te solicitamos algunos documentos, como la <0>Carpeta de impuestos</0> y el <1>RUT del Representante Legal de la empresa</1>. En algunos casos, puede ser necesario solicitar información adicional.",
    "To pay now": "A pagar ahora",
    "To protect your privacy and ensure the security of your data, we need to verify your account through the email we send you before showing your information.":
      "Para proteger tu privacidad y garantizar la seguridad de tus datos, necesitamos verificar tu cuenta a través del correo que te enviamos antes de mostrar tu información.",
    "To quote": "A cotizar",
    Total: "Total",
    "Total value": "Valor total",
    "Transfer included (round trip) throughout the country without incurring extra expenses":
      "Traslado ida y vuelta garantizado sin importar el destino",
    Transport: "Transporte",
    "Transport from": "Transporte desde",
    "Try again": "Intentar de nuevo",
    "Unexpected error": "Error inesperado",
    "Unexpected error. Please validate all inputs and retry.":
      "Error inesperado. Por favor valida todos los campos e intenta nuevamente.",
    "Unit value": "Valor unitario",
    Update: "Actualizar",
    Us: "Nosotros",
    "Use your credentials to log in to your account":
      "Utiliza tus credenciales para iniciar sesión en tu cuenta",
    Used: "Usado",
    VAT: "IVA",
    Validate: "Validar",
    "Validate your data": "Valida tu información",
    "Validation code": "Código de validación",
    "Valor final a cotizar, depende de ubicación geográfica.":
      "Valor final a cotizar, depende de ubicación geográfica.",
    "Visualize detailed budget": "Visualizar cotización detallada",
    "Visualize summarized budget": "Visualizar cotización resumida",
    "Warranty and support, in addition to all our support and accompaniment":
      "Garantía y respaldo, además de todo nuestro soporte y acompañamiento",
    "We are currently working on some server updates":
      "Estamos trabajando en actualizar nuestros servidores",
    "We are experts in machinery": "Somos expertos en maquinaria",
    "We are handling leases and sales through Whatsapp":
      "Estamos atendiendo arriendos y ventas mediante Whatsapp",
    "We are sorry": "Lo sentimos",
    "We are sorry, our site is not working properly. We know about this mistake and are working to fix it.":
      "Lo sentimos, nuestro sitio no está funcionando correctamente. Estamos al tanto de este error y estamos trabajando para corregirlo.",
    "We are the first digital agent for the rental and sale of machinery in Latam.":
      "Somos el primer agente digital de arriendo y venta de maquinaria en Latam.",
    "We call you in less than 30 minutes": "Te llamamos",
    "We can redirect you to the correct site or you would need to create another user to use it in this one.":
      "Te redirigiremos al sitio correspondiente a tu país. Si deseas continuar en este país tendrás que crear una cuenta nueva.",
    "We do not have machines, nor will we have them, because we are not your competition, we are your allies, with whom you can rent and/or sell your machines at your price, your time and your stock.":
      "No tenemos máquinas, ni las vamos a tener, porque no somos tu competencia, somos tus aliados, con quienes puedes arrendar y/o vender tus máquinas a tu precio, tus tiempos y tu stock.",
    "We have not found budgets for this request.":
      "No hay presupuestos disponibles para esta solicitud.",
    "We have personalized attention, insurance for your machines during transfers and work stays. More than 250 companies have preferred us with excellent results and experiences, join you too!":
      "Contamos con atención personalizada, seguros para tus máquinas  durante los traslados y estadía en obras. Más de 250 empresas nos han preferido con excelentes resultados y experiencias, ¡únete tú también!",
    "We have sent a budget to the mail you have given us":
      "Hemos enviado una cotización al correo que nos proporcionaste.",
    "We have sent a validation code to your email, enter it to recover your password":
      "Hemos enviado un código de validación a tu correo, ingresalo para recuperar tu contraseña",
    "We hope the next rental application is more aligned with you, stay tuned!":
      "Esperamos que la próxima solicitud de arriendo esté más alineada contigo, ¡mantente atento!",
    "We need to ask you for some information to quote as quickly as possible":
      "Necesitamos pedirte algunos datos para cotizar lo más rápido posible",
    "We pay you, coordinate with the client and take responsibility.":
      "Nosotros te pagamos, coordinamos con el cliente y nos hacemos responsables.",
    "We solve the lease and purchase of machinery for you":
      "Solucionamos el arriendo y compra de maquinaria por ti",
    "We take care of everything for you": "Nos encargamos de todo por ti",
    "We will call you to coordinate a meeting and thus, discuss details, answer your questions and specify some points.":
      "Te llamaremos para coordinar una reunión y así, conversar detalles, resolver tus dudas y concretar algunos puntos.",
    "We will contact you to define the rest of the payments":
      "Te contactaremos para coordinar los detalles y el resto del pago",
    "We will search through our network of suppliers and quickly send you a proposal":
      "Nuestro avanzado sistema de inteligencia artificial buscará entre miles de opciones para ofrecerte la mejor alternativa para ti",
    Week: "Semana",
    "Week from": "Semana desde",
    Welcome: "Bienvenido",
    "Welcome to RendaloMaq!": "¡Bienvenido a RendaloMaq!",
    "What do we gain?": "¿Qué ganamos nosotros?",
    "What do you earn?": "¿Qué ganas tú?",
    "What does it mean that they give me GPS?":
      "¿Qué significa que me regalen GPS?",
    "What does this mean? That we get people interested in renting or buying equipment like yours and thus specify its use through a lease or sale.":
      "¿Qué quiere decir esto? Que conseguimos personas interesadas en arriendo o compra de equipos como los tuyos y así concretar su uso a través de un arriendo o venta.",
    "What guarantees does RendaloMaq give me for leasing my machines?":
      "¿Qué garantías me da RendaloMaq por arrendar mis máquinas?",
    "What happens if I do not pass the validation? In that case, <0>we will refund the reservation money in full.</0>":
      "¿Qué sucede si no paso la validación? En ese caso, <0>te reembolsaremos el dinero de la reserva en su totalidad.</0>",
    "What is RendaloMaq in charge of?": "¿De qué se encarga RendaloMaq?",
    "What is RendaloMaq?": "¿Qué es RendaloMaq?",
    "What is the advantage for you? RendaloMaq takes care of absolutely everything, that is, you continue to earn the total you ask for renting the equipment, but you do not invest time or effort in negotiations, transfers or paperwork.":
      "¿Cuál es la ventaja para ti? RendaloMaq se hace cargo de absolutamente todo, es decir, tú sigues ganando el total que pides por arrendar el equipo, pero no inviertes tiempo ni esfuerzo en negociaciones, traslados ni papeleos.",
    "What is vendor software all about?":
      "¿De qué se trata el software para proveedores?",
    "What is your purchase planning?": "¿Cuál es tu planificación de compra?",
    "What you requested": "Lo que solicitaste",
    "When booking equipment, RendaloMaq fully guarantees the availability of the equipment for the day and place requested. In some cases, the risk department may contact you to request more information.":
      "Cuando reservas un equipo, RendaloMaq te garantiza la disponibilidad del equipo para el día y lugar solicitado. En algunos casos, el departamento de riesgos puede contactarte para solicitar más información.",
    "With guide and digital verifier": "Con guión y dígito verificador",
    "With guide and verifier digit": "Con guión y dígito verificador",
    "With the information you have providen us, this is your personalized budget":
      "Con la información que nos has proporcionado, esta es tu cotización personalizada",
    "Work with us": "Trabaja con nosotros",
    "Would you rather we call you later?":
      "¿Preferirías que te llamáramos después?",
    "Would you rather we call you now?":
      "¿Preferirías que te llamáramos ahora?",
    Year: "Año",
    "Yes, continue": "Si, continuar",
    "You are in good company": "Estás en buena compañía",
    "You are trying to login in a country different than the one you used to sign up. Please change to the correct country and log in.":
      "Estás tratando de iniciar sesión en un país diferente al usado al momento de registrarte. Por favor cambia al país correcto e inicia sesión.",
    "You can also redirect manually:":
      "También puedes redirigirte manualmente:",
    "You do not you have an account?": "¿No tienes una cuenta?",
    "You have no items in your cart yet":
      "No tienes elementos en tu carrito aún",
    "You have no items in your quote yet":
      "Aún no tienes elementos en tu cotización",
    "You save": "Ahorras",
    "You wan write the full address or only your commune":
      "Puedes escribir la dirección completa o sólo la comuna",
    "You will only need to pay a fraction of your lease today and coordinate the rest of the payments later on":
      "Solo tendrás que pagar una fracción del arriendo hoy y el resto de los pagos se coordinarán posteriormente con el equipo de operaciones.",
    "Your account does not belong to this country":
      "Tu cuenta de usuario no pertenece a este país",
    "Your account has been created!": "¡Has creado tu cuenta!",
    "Your assigned agent": "Tu ejecutivo comercial asignado",
    "Your budget is on its way, you will receive it in some minutes":
      "Tu cotización va en camino, en unos momentos la recibirás en tu correo.",
    "Your latest quote is next to expire":
      "Tu última cotización está a punto de expirar",
    "Your lease is guaranteed!": "Tu arriendo está garantizado",
    "Your request has been sent!": "¡Tu solicitud ha sido enviada!",
    "Your request is in process, The estimated waiting time is between 5 to 10 minutes.":
      "Su requerimiento esta siendo procesado, te contactaremos lo más pronto posible, el tiempo de espera es de 5 a 10 minutos.",
    "Your request is in process. One of our experts will call you in less than 30 minutes":
      "Tu solicitud está en proceso. Uno de nuestros expertos te contactará",
    "Your requirement is being validated to give you the best possible offer, we will contact you the":
      "Tu requerimiento está siendo validado para darte la mejor oferta posible, te contactaremos el día",
    "Your reservation has been received and is subject to a risk validation process.":
      "Tu reserva ha sido recibida y está sujeta a un proceso de validación de riesgos.",
    "Your transaction couldnt be completed":
      "Tu transacción no pudo ser completada",
    "Your user belongs to this country. You will get log out. Do you want to continue?":
      "Tu cuenta de usuario no pertenece al país al que deseas navegar. Si continúas, cerraremos tu sesión.",
    almost: "casi",
    associated: "asociados",
    at: "a",
    between: "entre las",
    buy: "Compra",
    clients: "clientes",
    "clients satified": "clientes satisfechos",
    concreted: "concretados",
    customers: "clientes",
    daily: "diario",
    day: "día",
    days: "días",
    "depends on geographical location.": "depende de la ubicación geográfica.",
    equipment: "maquinaria",
    from: "from",
    "have trusted us": "satisfechos",
    hourly: "por hora",
    hours: "horas",
    "https://api.whatsapp.com/send/?phone=56939170273&text&type=phone_number&app_absent=0":
      "https://api.whatsapp.com/send/?phone=56951979537&text&type=phone_number&app_absent=0",
    "https://api.whatsapp.com/send/?phone=56939170273&text=Hola%20soy%20proveedor%20y%20quiero%ser%20socio%20de%20Rendalo%20Maq!!&app_absent=0":
      "https://api.whatsapp.com/send/?phone=56951979537&text=Hola%2C+soy+proveedor+y+quiero+ser+parte+de+la+red+de+RendaloMaq&app_absent=0",
    "https://api.whatsapp.com/send/?phone=56939170273&text=Hola%2C%20requiero%20ayuda%20con%20un%20requerimiento&app_absent=0":
      "https://api.whatsapp.com/send/?phone=56939170273&text=Hola%2C%20requiero%20ayuda%20con%20un%20requerimiento&app_absent=0",
    "https://rendalomaq.notion.site/Trabaja-en-RendaloMaq-b6fcba31b10041609e10fb3011c0d013":
      "https://rendalomaq.notion.site/Trabaja-en-RendaloMaq-b6fcba31b10041609e10fb3011c0d013",
    "https://twitter.com/rendalomaq": "https://twitter.com/rendalomaq",
    'https://twitter.com/rendalomaq"': 'https://twitter.com/rendalomaq"',
    "https://www.facebook.com/rendalomaq":
      "https://www.facebook.com/rendalomaq",
    "https://www.instagram.com/rendalomaq/":
      "https://www.instagram.com/rendalomaq/",
    "https://www.linkedin.com/company/rendalomaq":
      "https://www.linkedin.com/company/rendalomaq",
    'https://www.linkedin.com/company/rendalomaq"':
      'https://www.linkedin.com/company/rendalomaq"',
    "https://www.youtube.com/watch?v=fi9U1wBaqpM":
      "https://www.youtube.com/watch?v=fi9U1wBaqpM",
    "in 15 minutes": "en 15 minutos",
    kilometers: "kilómetros",
    machines: "máquinas",
    minutes: "minutos",
    monthly: "mensual",
    nav_purchase_button: "Compra",
    "over the total price": "de descuento sobre el valor total",
    rent: "Arriendo",
    "rent {preferedPriceTemporality} from": [
      "Arriendo ",
      ["preferedPriceTemporality"],
      " desde",
    ],
    rented: "arrendada",
    rents: "arriendos",
    seconds: "segundos",
    "soporte@rendalomaq.cl": "soporte.cl@rendalomaq.com",
    "soporte@rendalomaq.com": "soporte.cl@rendalomaq.com",
    suppliers: "proveedores",
    "we contact you in less than 30 minutes": "nosotros te contactamos",
    weekly: "semanal",
    "{0}": [["0"]],
    "{preferedPriceTemporality}": [["preferedPriceTemporality"]],
  },
};
